<template>
  <div class="page__banner">
    <y-list-page
      ref="listPageRef"
      :pro-form-style="{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }"
      :form-style="{ textAlign: 'right' }"
      inline
      label-width="100px"
      :model="model"
      :fields="fields"
      :pagination="true"
      :submitter="{
        submitText: '搜索',
        resetButtonProps: {
          style: {
            display: 'none'
          }
        }
      }"
      :columns="columns"
      :fetch="fetchFn"
    >
      <template #form-before-addon>
        <el-button @click="add" type="primary">添加Banner</el-button>
      </template>
    </y-list-page>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  data() {
    return {
      model: {
        keyword: ''
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: '关键词',
            prop: 'keyword'
          }
        }
      ],
      columns: [
        {
          label: '标题',
          prop: 'title',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            return v
          }
        },
        {
          label: '图片',
          prop: 'imgUrl',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            return <el-avatar shape="square" size={'large'} src={v}></el-avatar>
          }
        },
        {
          label: '跳转类型',
          prop: 'urlType',
          render(h, v) {
            return this.$enumsMap.urlTypeMap[v] || '-'
          }
        },
        {
          label: '跳转地址',
          prop: 'url',
          render: (h, v) => {
            if (v == undefined || v === '') {
              return '-'
            }
            return (
              <el-tooltip
                class="item"
                effect="dark"
                content={v}
                placement="top"
              >
                <el-button type="text" vOn:click={() => this.openUrl(v)}>
                  打开链接
                </el-button>
              </el-tooltip>
            )
          }
        },
        {
          label: '创建时间',
          prop: 'createTime',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            return v
          }
        },
        {
          label: '修改时间',
          prop: 'updateTime',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            return v
          }
        },
        {
          label: '操作',
          render: (h, record) => {
            return (
              <div>
                <el-button vOn:click={() => this.toModify(record)} type="text">
                  修改
                </el-button>
                <el-button
                  vOn:click={() => this.deleteAction(record)}
                  type="text"
                >
                  删除
                </el-button>
              </div>
            )
          }
        }
      ]
    }
  },
  methods: {
    fetchFn({ currentPage, ...data }) {
      return this.$serve
        .bannerList({
          data: {
            ...data,
            pageIndex: currentPage
          }
        })
        .then((res) => {
          if (res === this.$serve.FAIL) {
            return Promise.reject()
          }
          return {
            ...res,
            page: res.pageIndex
          }
        })
    },
    add() {
      this.$router.push('/banner/add')
    },
    toModify(record) {
      this.$router.push(`/banner/update/${record.id}`)
    },
    deleteAction(record) {
      this.$confirm('确认删除吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delete(record)
        })
        .catch(() => {})
    },
    async delete(record) {
      const res = await this.$serve.bannerDelete({
        aimid: record.id
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('删除成功')
      this.$refs.listPageRef.fetchData()
    },
    openUrl(v) {
      window.open(v)
    }
  }
}
</script>
